@font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Thin.otf");
  font-weight: 100;
}

@font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Light.otf");
  font-weight: 300;
}

@font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Regular.otf");
  font-weight: normal;
}

@font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Semibold.otf");
  font-weight: 600;
}

@font-face {
  font-family: "CharlieDisplay";
  src: url("./assets/fonts/CharlieDisplay/CharlieDisplay-Bold.otf");
  font-weight: bold;
}

@font-face {
  font-family: "OCRAExtended";
  src: url("./assets/fonts/OCRAExtended/OCRAExtended.ttf");
  font-weight: normal;
}

* {
  margin: 0;
  padding: 0px;
  box-sizing: border-box;
}

html {
  font-size: 20px;
  color: #172b4d;
}

html,
body {
  height: 100%;
  background-color: #f7f6f3;
}

body {
  margin: 0;
  font-family: "CharlieDisplay", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
  width: 100%;
}

code {
  font-family: "OCRAExtended", source-code-pro, Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 0.2s ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.ReactModal__Content {
  max-width: none !important;
}

.Toastify__toast-container.toast__container,
.Toastify__toast.toast__toast,
.Toastify__toast-body.toast__body {
  padding: 0px;
  margin: 0;
  border-radius: 3px;
}

.Toastify__toast-container.toast__container {
  width: 400px;
  top: 3em;
  transform: translateX(-50%);
}
.Toastify__toast.toast__toast {
  margin-bottom: 1rem;
  box-shadow: 0 2px 4px 0 rgba(69, 69, 69, 0.2);
}

.Toastify__toast.toast__toast {
  min-height: auto;
}

.bold-text {
  font-weight: bold;
}

.bold-text--600 {
  font-weight: 600;
}

.fadeIn {
  animation: fadeIn 0.3s ease-in-out 0s 1 normal none running;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
